import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import '../CSS/style.css';
// import img from '../img/bank/1678279059730.jpg'


export default function Realisations(){
    return (
        <>
        <div className="container my-5 mx-lg-auto " >
            <div className="row " id="realisations">
                <h1 className="text-uppercase my-5">Réalisations</h1>
            </div>
        <div className="row bg-dark pt-5  d-flex justify-content-center" style={{height: '70vh', width:'70vw'}}>
        <Carousel className="" >
      <Carousel.Item className="col-4 frame" style={{height: '50vh'}}>
        <h3 style={{color:'white'}}><a href="https://www.start-zup.com" target="__blank">Start-Zup.com</a> (+ Back Office)</h3>
        <iframe src="https://www.start-zup.com" title="sz" style={{height:'300px', width:'500px'}}></iframe>
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item className="col-4 frame" style={{height: '50vh'}}>
          <h3 style={{color:'white'}}><a href="https://www.vievaconnect.com" target="__blank">vievaconnect.com</a></h3>
          <iframe src="https://www.vievaconnect.com"  title="vc" style={{height:'300px', width:'500px'}}></iframe>

        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item className="col-4 frame" style={{height: '60vh'}}>
          <h3 style={{color:'white'}}><a href="https://what-to-watch-psi.vercel.app/" target="__blank">What to Watch</a></h3>
          <iframe src="https://what-to-watch-psi.vercel.app/"  title="vc" style={{height:'300px', width:'500px'}}></iframe>
          <p>Technologie: React</p>

        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
      </Carousel.Item>
      {/* <Carousel.Item>

<Carousel.Caption>
<h3>Third slide label</h3>
<p>
Praesent commodo cursus magna, vel scelerisque nisl consectetur.
</p>
</Carousel.Caption>
</Carousel.Item> */}
    </Carousel>
        </div>
</div>
        <hr className="my-5 py-0" />
        </>
    )
}