import React from "react";
import "../CSS/style.css";

export default function Progress() {
    return (
        <>
            <div className="container-fluid">
            <div className="row mb-5" id="skills">
                <h1 className="text-uppercase my-5">Compétences</h1>
                <div className="col-lg-4">
                    <h2>Front</h2>
                    <section className="progressBar container py-3">
                        <p>HTML</p>
                        <div className="contain mb-5">
                            <div className="skills html my-auto">90</div>
                        </div>
                        <p>CSS</p>
                        <div className="contain mb-5">
                            <div className="skills css">90</div>
                        </div>
                        <p>JavaScript</p>
                        <div className="contain mb-5">
                            <div className="skills javascriptFront">80</div>
                        </div>
                    </section>
                </div>
                <div className="col-lg-4">
                    <h2>Back</h2>
                    <section className="progressBar container py-3">
                        <p>PHP</p>
                        <div className="contain mb-5">
                            <div className="skills html">90</div>
                        </div>
                        <p>JavaScript</p>
                        <div className="contain mb-5">
                            <div className="skills javascriptBack">75</div>
                        </div>
                        <p>PHP</p>
                        <div className="contain mb-5">
                            <div className="skills php">60</div>
                        </div>
                    </section>
                </div>
                <div className="col-lg-4">
                    <h2>BDD</h2>
                    <section className="progressBar container py-3">
                        <p>SQL</p>
                        <div className="contain mb-5">
                            <div className="skills html">90</div>
                        </div>
                        <p>Node Js</p>
                        <div className="contain mb-5">
                            <div className="skills css">80</div>
                        </div>
                        <p>Mongo DB</p>
                        <div className="contain mb-5">
                            <div className="skills php">60</div>
                        </div>
                    </section>
                </div>
            </div>
            </div>
            <hr className="my-5" />
        </>
    );
}
