// import logo from './logo.svg';
import './App.css';
import Home from './componants/Home';
import '../src/CSS/style.css';
import Progress from './componants/Progress';
import Footer from './componants/partiels/Footer';
import Navb from './componants/partiels/Nav';
import Realisations from './componants/Realisations';
import Contact from './componants/Contact';
import Who from './componants/Who';
import Tarifs from './componants/Tarifs';


function App() {
  
  return (
    <>
    <div className="App container-fluid p-0">
      <Navb />
      <Home />
      <Who />
      <Progress />
      <Realisations />
      <Tarifs />
      <Contact />
      <Footer />
    </div>
    </>
  );
}

export default App;
