import React from "react";
// import imgProfile from '../img/1678279059730-removebg-preview.png'
import '../CSS/style.css'

export default function Home() {
    return (
        <>
            <div className="container-fluid  home">
                <div className="row">
                {/* <div className="col-lg-1 pt-3">
                        <img id="profilePic" src={imgProfile} alt="" />
                    </div> */}
                    <div className="col text-center mt-5 text-uppercase h1">
                        <p style={{ fontSize: "6rem" }}>Wladev</p><br />
                        <p>Développeur Web / Web mobile</p>
                      
                    </div>
                </div>
                
                
            </div>
            
        </>
    );
}
