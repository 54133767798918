import React from "react";

export default function Footer(){
    return (
        <>
        <div className="container-fluid px-0 py-0 my-0">
<footer className="bg-body-tertiary text-center text-lg-start">

  <div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
    © 2024 Copyright:
    Wladev - Tous droits réservés
  </div>

</footer>
</div>
</>
    )
}