import React from "react";

export default function Tarifs(){
    return(
        <>
        <div className="container-fluid">
            <div className="row">
                <div className="col">
        <h1 className="text-uppercase">Tarifs</h1>

                </div>
            </div>
            <div className="row d-flex justify-content-center">
            <div className="col-lg-8 fs-4 mt-5">
                <p>Le coût de développement d'un site web ou d'une application dépend de trois facteurs clés : <br /><br /> <span className="text-uppercase py-5">  le temps, le prix, et les fonctionnalités.</span> <br /><br /> Ce que l'on appelle le "triangle du projet" fonctionne de la manière suivante :<br />

<br />1. Temps : Plus le délai de livraison est court, plus le coût peut être élevé en raison de l'intensité de travail nécessaire. En revanche, un délai plus long peut permettre une meilleure répartition des coûts.<br />

<br />2. Prix : Le budget que vous êtes prêt à investir influencera directement la qualité et la quantité des fonctionnalités développées ainsi que le délai de réalisation.<br />

<br />3. Fonctionnalités : Plus votre projet nécessite de fonctionnalités complexes ou personnalisées, plus il faudra de temps et de ressources pour les développer. Des fonctionnalités basiques et standardisées seront plus économiques et rapides à implémenter.<br /><br />

<p className="text-uppercase my-5"><br />Comment cela se traduit en pratique ?<br /></p>
<p className="fs-3">Pour offrir une transparence totale, voici comment je facture mes services :</p><br /><br /><br />

<span style={{color:'blue'}} className="text-uppercase">Site Vitrine : À partir de 800 €</span> :<br/><br />

Un site simple avec quelques pages (Accueil, À propos, Services, Contact)
Design personnalisé et adapté à vos besoins
Intégration des réseaux sociaux<br /><br /><br /><br />
<span style={{color:'blue'}} className="text-uppercase">Site E-commerce : À partir de 1500 €</span><br/><br />

Catalogue de produits
Système de paiement sécurisé
Gestion des stocks et des commandes<br /><br />

<span style={{color:'blue'}} className="text-uppercase"> Applications Web Personnalisées : Sur devis</span><br /><br />

Développement sur mesure selon vos spécifications
Intégration d'APIs, fonctionnalités avancées.<br />
Chaque projet est unique, c'est pourquoi il est essentiel de discuter de vos besoins spécifiques pour établir un devis précis. Je suis flexible et à l'écoute pour trouver le meilleur équilibre entre vos attentes, votre budget et le délai souhaité.</p>
            </div>
        </div>
        </div>
        <hr />
        </>
    )
}