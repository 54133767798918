import React from "react";

export default function Who(){
    return(
        <>
        <div className="container my-5">
            <div className="row" id="who">
                <h1 className="text-uppercase">Qui suis-je ?</h1>
            </div>
            <div className="row mt-5 d-flex justify-content-center">
                <div className="col-lg-8  fs-4">
                    <p>
                    Hello World! <br /> Je suis Wladimir. Après avoir exercé pendant 10 ans en tant que conducteur de train, j'ai décidé de suivre ma passion pour le développement web. J'ai obtenu le titre professionnel de Développeur Web et Web Mobile (DWWM) en juin 2024. Aujourd'hui, je suis développeur web freelance, prêt à mettre mes compétences au service de vos projets numériques. Fort de mon expérience passée lors de ma formation et ma période de stage en entreprise (sans compter les projets personnels), je combine rigueur, précision et créativité pour offrir des solutions web innovantes et efficaces.


                    </p>
                </div>
            </div>
        </div>
        </>
    )
}