import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import imgProfile from '../../img/1678279059730-removebg-preview.png';

export default function Navb() {
  
  return (
    <Navbar className='container-fluid fs-5' expand="lg" bg="dark" data-bs-theme="dark">
      <Container>
      <img id="profilePic" className='pe-5 py-2' src={imgProfile} alt="" />
        <Navbar.Brand className='fs-4' href="#home">Wladev</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto bg-dark">
            <Nav.Link href="#who">Qui&nbsp;suis-je&nbsp;?</Nav.Link>
            <Nav.Link href="#skills">Mes compétences</Nav.Link>
            <Nav.Link href="#realisations">Réalisations</Nav.Link>
            <Nav.Link href="#tarifs">Tarifs</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
            {/* <NavDropdown title="Réalisations" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
