import React from "react";
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";

export default function Contact(){
    return(
        <>
        <div className="container-fluid mt-5 contact py-5">
            <div className="row mb-5" id="contact">
                <h1 className="text-uppercase">Contact</h1>
            </div>
        <div className="row d-flex justify-content-center">
        <div className="col-6 ">

    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Nom</Form.Label>
        <Form.Control type="text" placeholder="Nom" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Prénom</Form.Label>
        <Form.Control type="text" placeholder="Prénom" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Entreprise</Form.Label>
        <Form.Control type="text" placeholder="Entreprise" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Votre email</Form.Label>
        <Form.Control type="email" placeholder="email" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Téléphone</Form.Label>
        <Form.Control type="tel" placeholder="téléphone" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
      <Button type="submit" style={{backgroundColor:'grey', borderColor:'white', width:'10vw'}}>Envoyer</Button>
    </Form>
        </div>
        </div>
        </div>
        </>
    )
}